import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PostLayout.tsx";
import BlogHeading from '../../components/BlogHeading';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BlogHeading heading={props.pageContext.frontmatter.title} secondHeading={props.pageContext.frontmatter.description} mdxType="BlogHeading" />
    <p>{`Today is Friday. Here's my recap of the past few days. `}</p>
    <p>{`I would like to create something that could fit into AM Radio.`}</p>
    <h2>{`What is my inspiration`}</h2>
    <iframe style={{
      "borderRadius": "12px"
    }} src="https://open.spotify.com/embed/track/1AOar35ofnqiI2tVMZxmU7?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    <iframe style={{
      "borderRadius": "12px"
    }} src="https://open.spotify.com/embed/track/6GiFcHzZtT7NB7ZPk4ZmEd?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      